import { ErrorParagraph } from 'components/screens/log-hours/styles';
import { MYSQL_DATE_STRING } from 'config';
import format from 'date-fns/format';
import { CreateMeetingRequest } from 'generated-types/meeting';
import { forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, DatePicker, Input, Row, TagPicker } from 'rsuite';
import { PickerInstance } from 'rsuite/esm/Picker';

import { Props as ResolutionsProps, Resolutions } from './Resolutions/Resolutions';

export type Participant = {
    id: number;
    firstName: string;
    lastName: string;
};

export type MeetingFormProps = {
    onSubmit: (m: CreateMeetingRequest) => void;
    possibleParticipants: Participant[];
    resolutionsProps: ResolutionsProps;
};

export function AddMeetingForm(props: MeetingFormProps) {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormFields>({
        defaultValues: { date: new Date(), notes: '', topic: '', participants: [] },
    });

    const makeMeeting = (data: FormFields) => ({
        date: format(data.date, MYSQL_DATE_STRING),
        topic: data.topic,
        notes: data.notes,
        participant_ids: data.participants,
        budget: 0,
        member_ids: [],
    });

    return (
        <form onSubmit={handleSubmit(data => props.onSubmit(makeMeeting(data)))}>
            <Row>
                <Col xs={4}>
                    <p>
                        Date<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="date"
                        control={control}
                        render={({ field }) => <DatePicker oneTap cleanable={false} {...field} />}
                    />
                </Col>
                <Col xs={20}>
                    <p>
                        Topic<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="topic"
                        control={control}
                        rules={{ required: 'Meeting topic is required' }}
                        render={({ field }) => <Input {...field} />}
                    />
                    <ErrorParagraph>{errors?.topic?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Participants<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="participants"
                        control={control}
                        rules={{
                            validate: values => values.length > 0,
                        }}
                        render={({ field }) => (
                            <ParticipantSelector
                                selectFrom={props.possibleParticipants}
                                {...field}
                            />
                        )}
                    />
                    {errors?.participants && (
                        <ErrorParagraph>At least one participant is required</ErrorParagraph>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Notes<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="notes"
                        control={control}
                        rules={{ required: 'Meeting notes are required' }}
                        render={({ field }) => (
                            <Input as={'textarea'} rows={12} size="lg" {...field} />
                        )}
                    />
                    <ErrorParagraph>{errors?.notes?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ marginTop: 20 }}>
                    <Resolutions {...props.resolutionsProps} />
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ marginTop: 20 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            appearance="primary"
                            color="green"
                            style={{ width: 300 }}
                        >
                            Create meeting minutes
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
}

type ParticipantSelectorProps = {
    selectFrom: Participant[];
    value: number[];
    onChange: (v: number[]) => void;
};

const ParticipantSelector = forwardRef<PickerInstance, ParticipantSelectorProps>((props, ref) => {
    const byLastName = (a: Participant, b: Participant) => (a.lastName > b.lastName ? 1 : -1);
    const valuesToSelect = props.selectFrom.sort(byLastName).map(item => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
    }));

    return (
        <TagPicker
            ref={ref}
            placeholder="Select participants"
            data={valuesToSelect}
            style={{ width: '100%' }}
            value={props.value}
            onChange={props.onChange}
        />
    );
});
ParticipantSelector.displayName = 'ParticipantSelector';

type FormFields = {
    date: Date;
    topic: string;
    notes: string;
    participants: number[];
};
