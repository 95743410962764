import ProcountorDeliveryReportList from 'components/misc/ProcountorDeliveryReportList';
import { useCustomerProjects } from 'hooks/useCustomerProjects';
import { useCustomers } from 'hooks/useCustomers';
import { useTimeTracks } from 'hooks/useTimeTracks';
import { useEffect, useState } from 'react';
import { Button, Col, FlexboxGrid, Grid, Loader, Modal, Panel, Toggle } from 'rsuite';
import { getPreviousMonthRange } from 'utils/dateUtils';

import { useProfile } from '../../hooks/useProfile';
import EditCustomerProjectDialog from '../dialogs/EditCustomerProjectDialog';
import CustomerProjectTable from '../tables/CustomerProjectTable';

const CustomerProjectsScreen = () => {
    const { isAdmin } = useProfile();
    const { customers } = useCustomers();
    const { customerProjects, sendToProcountor, isSuccess } = useCustomerProjects();
    const [showInactive, setShowInactive] = useState(false);
    const filteredProjects = showInactive
        ? customerProjects
        : customerProjects.filter(project => project.is_active);

    const [prevMonthStart, prevMonthEnd] = getPreviousMonthRange(new Date());
    // used to construct the ttQuery correctly
    const { ttQuery, setTtQuery } = useTimeTracks({});
    const [modalStatus, setModalStatus] = useState<{
        isOpen: boolean;
        error?: string;
        data?: { success: number[]; noTimetracks: number[]; failed: number[] };
    }>({
        isOpen: false,
        error: undefined,
        data: undefined,
    });
    const handleModalClose = () =>
        setModalStatus({ isOpen: false, error: undefined, data: undefined });

    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        setTtQuery({
            // Is this used for anything in procounter integration? no, but it IS used for fetching time tracks for resolving which projects to send to procountor
            customer_projects: filteredProjects.map(project => project.id),
            start_date: prevMonthStart,
            end_date: prevMonthEnd,
        });
    }, []);

    /**
     * Send each active projects' last month's hours to Procountor.
     * Each separate request will respond with
     *  - 200 for success
     *  - 404 for "no timetracks found"
     *  - any other error for failed input
     * Displays a dialog with consolidated results at the end
     */
    const handleSendEachCustomerProjectToProcountor = () => {
        console.log('Bruteforce action');

        // put project id's in array
        const projectIds: number[] = filteredProjects.map(project => project.id);

        const successfulInputs: number[] = [];
        const noTimetrackInputs: number[] = [];
        const failedInputs: number[] = [];

        setIsSending(true);

        // iterate all project id's and send each individually to procountor
        const actions = projectIds.map(id =>
            // Input the project id to the time track query
            sendToProcountor(id, { ...ttQuery, customer_projects: [id] })
                .then(() => {
                    successfulInputs.push(id);
                })
                .catch((error: { response: { status: number } }) => {
                    if (error.response.status === 404) {
                        noTimetrackInputs.push(id);
                    } else {
                        failedInputs.push(id);
                    }
                }),
        );

        // Resolve each sending to procountor before exiting to results modal
        Promise.all(actions)
            .then(() => {
                setModalStatus({
                    isOpen: true,
                    data: {
                        success: successfulInputs,
                        noTimetracks: noTimetrackInputs,
                        failed: failedInputs,
                    },
                });
                setIsSending(false);
            })
            .catch(reason => {
                setModalStatus({
                    isOpen: true,
                    error: `An error occurred: ${reason.toString()}`,
                });
                setIsSending(false);
            });
    };

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Customer Projects</h1>
                            </Panel>

                            <Panel>
                                <span>
                                    <small>Display All</small>
                                </span>
                                <Toggle
                                    style={{ marginLeft: '6px' }}
                                    checked={showInactive}
                                    onChange={() => setShowInactive(!showInactive)}
                                />
                                {isAdmin ? (
                                    <>
                                        <EditCustomerProjectDialog>
                                            <Button appearance="primary">
                                                Add Customer Project
                                            </Button>
                                        </EditCustomerProjectDialog>
                                        <Button
                                            style={{ marginLeft: '6px' }}
                                            appearance="primary"
                                            onClick={handleSendEachCustomerProjectToProcountor}
                                            target="_blank"
                                            loading={isSending}
                                        >
                                            Send all to Procountor
                                        </Button>
                                    </>
                                ) : null}
                            </Panel>
                            <Modal open={modalStatus.isOpen} onClose={handleModalClose}>
                                <Modal.Header>Procountor delivery results</Modal.Header>
                                <Modal.Body>
                                    {modalStatus.error ? (
                                        modalStatus.error
                                    ) : (
                                        <>
                                            <ProcountorDeliveryReportList
                                                header="Successfully delivered"
                                                data={modalStatus.data?.success}
                                                projects={filteredProjects}
                                                customers={customers}
                                            />
                                            <ProcountorDeliveryReportList
                                                header="No timetracks found"
                                                data={modalStatus.data?.noTimetracks}
                                                projects={filteredProjects}
                                                customers={customers}
                                            />
                                            <ProcountorDeliveryReportList
                                                header="Failed to deliver"
                                                data={modalStatus.data?.failed}
                                                projects={filteredProjects}
                                                customers={customers}
                                            />
                                        </>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleModalClose} appearance="primary">
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </FlexboxGrid>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={
                            <h3>
                                Customer Projects (
                                {isSuccess ? filteredProjects.length : <Loader />})
                            </h3>
                        }
                    >
                        <CustomerProjectTable
                            inAdminView={true}
                            customerProjects={filteredProjects}
                            customers={customers}
                        />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};

export default CustomerProjectsScreen;
