import { CustomerList } from 'generated-types/customer';
import { CustomerProjectList } from 'generated-types/customer_project';
import { List } from 'rsuite';

type Props = {
    data?: number[];
    projects: CustomerProjectList;
    customers: CustomerList;
    header: string;
};

const ProcounterDeliveryReportList = (props: Props) => {
    const { data, projects, customers, header } = props;

    if (!data || data.length === 0)
        return (
            <>
                <h6 style={{ marginBottom: '8px' }}>{header}</h6>
                <div style={{ marginBottom: '16px', opacity: 0.6 }}>No results</div>
            </>
        );

    return (
        <>
            <h6 style={{ marginBottom: '8px' }}>{header}</h6>
            <List size="sm" style={{ marginBottom: '16px' }}>
                {data?.map(id => {
                    const project = projects.find(project => project.id === id);
                    const customer = customers.find(
                        customer => customer.id === project?.customer_id,
                    );
                    return (
                        <List.Item key={id}>
                            {project?.name} ({customer?.name})
                            <br />
                            {project?.assignees}
                        </List.Item>
                    );
                })}
            </List>
        </>
    );
};

export default ProcounterDeliveryReportList;
